import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Spinner,
  Badge,
  Modal,
  Button,
  Pagination,
} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import moment from "moment";

import {
  onGetWithdrawRequestList,
  onCancelWithdrawRequest,
  WithdrawRequest,
  WithdrawStatus,
  WithdrawQueryInput,
} from "./service";
import { ToastMessage } from "../../components/ToastMessage";

const WithdrawRequestPage = () => {
  const [params, setParams] = useState<WithdrawQueryInput>({
    page: 1,
    limit: 10,
    status: [],
  });
  const [data, setData] = useState<WithdrawRequest[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [loadingModalButton, setLoadingModalButton] = useState(false);
  const [selectedId, setSelectId] = useState<string>("");
  const [paginateItems, setPaginateItems] = useState<any[]>([]);

  const [toastOptions, setToastOptions] = useState({
    bg: "Light" as any,
    title: "",
    body: "",
    visible: false,
  });

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectId("");
  };

  const handleGetWithdrawRequestList = async (params: WithdrawQueryInput) => {
    setLoading(true);
    const res = await onGetWithdrawRequestList(params);
    if (res && res.data) {
      setLoading(false);
      setData(res.data);
      let active = params.page;
      let paginateItems = [];
      for (
        let number = 1;
        number <= Math.round(res.total / params.limit);
        number++
      ) {
        paginateItems.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => onChangePage(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
      setPaginateItems(paginateItems);
    } else {
      setData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetWithdrawRequestList(params);
  }, [params]);

  const confirmToCancel = (_id: string) => {
    setShowModal(true);
    setSelectId(_id);
  };

  const handleCancelWithDrawRequest = async () => {
    setLoadingModalButton(true);
    try {
      if (!selectedId) return;
      const res = await onCancelWithdrawRequest(selectedId);
      if (res && res.data) {
        setTimeout(() => {
          setShowModal(false);
          setLoadingModalButton(false);
          setSelectId("");
          setToastOptions({
            bg: "Info",
            title: "Cancel Successfully",
            body: "Woohoo, you canceled this withdraw request!",
            visible: true,
          });
          setParams({ ...params });
        }, 3000);
      } else {
        setTimeout(() => {
          setShowModal(false);
          setLoadingModalButton(false);
          setSelectId("");
          setToastOptions({
            bg: "Warning",
            title: "Cancel Fail",
            body: "Hm, something went wrong!",
            visible: true,
          });
          setParams({ ...params });
        }, 3000);
      }
    } catch (error) {
      setTimeout(() => {
        setShowModal(false);
        setLoadingModalButton(false);
        setSelectId("");
        setToastOptions({
          bg: "Warning",
          title: "Cancel Fail",
          body: "Hm, something went wrong!",
          visible: true,
        });
        setParams({ ...params });
      }, 3000);
    }
  };

  const onChangePage = (page: number) => {
    setParams({ ...params, page });
  };

  return (
    <div>
      <div className="title-page color-full">Withdraw Funds</div>
      {loading ? (
        <div className="text-center mt-4">
          <Spinner animation="border" />
        </div>
      ) : (
        <Row>
          <Col xs={12}>
            <Table striped bordered hover>
              <thead>
                <tr className="text-center">
                  <th>#</th>
                  <th>Created Time</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>:::</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((item: WithdrawRequest, index: number) => {
                    let badgeBg = "success";
                    switch (item.status) {
                      case WithdrawStatus.PENDING:
                        badgeBg = "info";
                        break;
                      case WithdrawStatus.REJECT:
                        badgeBg = "secondary";
                        break;
                      case WithdrawStatus.CANCELED:
                        badgeBg = "danger";
                        break;
                      default:
                        badgeBg = "success";
                        break;
                    }
                    return (
                      <tr key={index} className="text-center">
                        <td
                          style={{
                            width: "50px",
                            fontSize: "13px",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            width: "300px",
                            overflowX: "auto",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          {moment(item.createdAt).format("HH:mm MM/DD/YYYY")}
                        </td>

                        <td
                          style={{
                            width: "250px",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          <code>{item.amount}</code>
                        </td>
                        <td
                          style={{
                            width: "150px",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          <Badge pill bg={badgeBg}>
                            {item.status}
                          </Badge>
                        </td>
                        <td
                          style={{
                            width: "100px",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          {item.status === WithdrawStatus.PENDING && (
                            <Badge
                              pill
                              bg="danger"
                              style={{ cursor: "pointer" }}
                              onClick={() => confirmToCancel(item._id)}
                            >
                              Cancel
                            </Badge>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            {data.length <= 0 && (
              <div
                style={{ width: "100%", opacity: "0.8" }}
                className="text-center mt-3"
              >
                No data found
              </div>
            )}
            <Pagination size="sm">{paginateItems}</Pagination>
          </Col>
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Cancel Withdraw Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {loadingModalButton ? (
                <div className="text-center">
                  <Spinner animation="border" />
                </div>
              ) : (
                <div>Confirming to cancel a withdraw request!</div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={handleCloseModal}>
                No
              </Button>

              <Button
                size="sm"
                variant="success"
                disabled={loadingModalButton}
                onClick={handleCancelWithDrawRequest}
              >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
          <ToastMessage
            bg={toastOptions.bg}
            title={toastOptions.title}
            body={toastOptions.body}
            visible={toastOptions.visible}
            onClose={() =>
              setToastOptions({
                ...toastOptions,
                visible: false,
              })
            }
          />
        </Row>
      )}
    </div>
  );
};

export default WithdrawRequestPage;
