import "react-confirm-alert/src/react-confirm-alert.css";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import { AuthProvider } from "./components/AuthContext";
import LayoutAdmin from "./layout/LayoutAdmin";
import LayoutAuth from "./layout/LayoutAuth";
import ForgotPasswordPage from "./pages/Auth/ForgotPassword";
import LoginPage from "./pages/Auth/Login";
import RegisterPage from "./pages/Auth/Register";
import CampaignLayout from "./pages/Campaign/CampaignLayout";
import CampaignExpired from "./pages/Campaign/components/CampaignExpired";
import CampaignRunning from "./pages/Campaign/components/CampaignRunning";
import DashboardPage from "./pages/Dashboard";
import NfPage from "./pages/Nft";
import WithdrawRequestPage from "./pages/Withdraw";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/auth" element={<LayoutAuth />}>
          <Route index element={<LoginPage />}></Route>
          <Route path="login" element={<LoginPage />}></Route>
          <Route path="register" element={<RegisterPage />}></Route>
          <Route
            path="forgot-password"
            element={<ForgotPasswordPage />}
          ></Route>
        </Route>

        {/* CMS */}
        <Route path="/" element={<LayoutAdmin />}>
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route path="dashboard" element={<DashboardPage />}></Route>
          <Route path="campaign" element={<CampaignLayout />}>
            <Route index element={<CampaignRunning />}></Route>
            <Route path="running" element={<CampaignRunning />}></Route>
            <Route path="expired" element={<CampaignExpired />}></Route>
          </Route>
          <Route path="nfts" element={<NfPage />}></Route>
          <Route
            path="withdraw-request"
            element={<WithdrawRequestPage />}
          ></Route>
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;
