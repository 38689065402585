import { isEmpty } from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Col, Form, Pagination, Row, Spinner } from "react-bootstrap";
import useDebounce from "../../../hooks/useDebounce";
import { convertCase } from "../../../utils/utils";
import { onGetCategories } from "../../AddPayment/service";
import {
  ExpiredStatus,
  getMyCollection,
  IDataCollection,
  IParams,
} from "../service";
import CampaignCard from "./CampaignCard";
// @ts-ignore
import DatePicker from "react-datepicker";
import moment from "moment";

const CampaignExpiredPage: React.FC<{}> = () => {
  const [dataSelect, setDataSelect] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<IParams>({
    page: 1,
    limit: 12,
    expiredStatus: ExpiredStatus.EXPIRED,
    category: undefined,
    startTime: undefined, // YYYY-MM-DD
    endTime: undefined, // YYYY-MM-DD
    search: undefined,
  });
  const [total, setTotal] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const debouncedValue = useDebounce<string>(search, 500);
  const [dataCollection, setDataCollection] = useState<IDataCollection[]>([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    onGetDataCollection({ ...filter, search: debouncedValue || undefined });
  }, [filter, debouncedValue]);

  const onGetDataCollection = async (filter: IParams) => {
    setLoading(true);
    try {
      const res = await getMyCollection(filter);
      if (res && res?.status === "SUCCESS") {
        setDataCollection(res?.data);
        setTotal(res?.total);
      } else {
        setDataCollection(res?.data);
        setTotal(0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    onGetDataCategories();
  }, []);

  const onGetDataCategories = async () => {
    const res = await onGetCategories();
    if (res && res?.status === "SUCCESS") {
      setDataSelect(res?.data);
    } else {
      setDataSelect([]);
    }
  };

  //
  const onChangePage = (number: number) => {
    setFilter({ ...filter, page: number });
  };
  const renderPagination = (totalTable: number) => {
    const limitPage = Math.ceil(totalTable / filter.limit);
    const active = filter.page;
    const items = [];
    for (let number = 1; number <= limitPage; number++) {
      items.push(
        <Pagination.Item
          onClick={() => onChangePage(number)}
          key={number}
          active={number === active}
        >
          {number}
        </Pagination.Item>
      );
    }

    return <Pagination>{items}</Pagination>;
  };

  return (
    <div>
      <Row className="mt-4">
        <Col xs={4}>
          <Form.Group className="mb-3">
            <Form.Control
              placeholder="Search here"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setSearch(event.target.value)
              }
            />
          </Form.Group>
        </Col>
        <Col span={6}>
          <Row>
            <Col xs={6}>
              <DatePicker
                selected={startDate}
                onChange={(date: any) => {
                  setStartDate(date);
                  setFilter({
                    ...filter,
                    startTime: date
                      ? moment(date).format("YYYY-MM-DD")
                      : undefined,
                  });
                }}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className="form-control date-picker-input"
                placeholderText="MM/DD/YYYY"
                isClearable={Boolean(startDate)}
              />
            </Col>
            <Col xs={6}>
              <DatePicker
                selected={endDate}
                onChange={(date: any) => {
                  setEndDate(date);
                  setFilter({
                    ...filter,
                    endTime: date
                      ? moment(date).format("YYYY-MM-DD")
                      : undefined,
                  });
                }}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className="form-control date-picker-input"
                placeholderText="MM/DD/YYYY"
                isClearable={Boolean(endDate)}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={3}>
          <Form.Group className="mb-3">
            <Form.Select
              onChange={(value: any) => {
                setFilter({
                  ...filter,
                  category:
                    value.target.value !== "SELECT_MENU"
                      ? value.target.value
                      : undefined,
                });
              }}
            >
              <option value="SELECT_MENU">Open this select menu</option>
              {dataSelect &&
                dataSelect.length > 0 &&
                dataSelect.map((d: any, i: number) => {
                  return (
                    <option value={d.name} key={`cate_${i + 1}`}>
                      {d.name}
                    </option>
                  );
                })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      {loading ? (
        <div className="text-center mt-5">
          <Spinner animation="border" />
        </div>
      ) : isEmpty(dataCollection) ? (
        <div className="mt-1">No Data</div>
      ) : (
        <>
          <CampaignCard
            data={dataCollection}
            onReloadTable={() => {
              setFilter({ ...filter });
            }}
          />
          {total > 0 ? (
            <div>
              <Row>
                <Col xs={12} className="text-right">
                  {renderPagination(total)}
                </Col>
              </Row>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default CampaignExpiredPage;
