import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import close from "../assets/images/close.png";
import ntfImage from "../assets/images/nft.png";
import { useAuth } from "./AuthContext";

export interface ModalProps {
  visible: boolean;
  onClose?: () => void;
  image?: any;
  titleContent: any;
  content: any;
  textButton: string;
  to: string;
  targetBlank?: boolean;
}

const ModalComponent: React.FC<ModalProps> = (props) => {
  const navigate = useNavigate();
  const { reloadCard, setReloadCard } = useAuth();

  const onClickButton = () => {
    props.onClose && props.onClose();
    setReloadCard(!reloadCard);
    if (props.targetBlank) window.open(props.to);
    else navigate(props.to);
  };

  return (
    <Modal show={props.visible} className="modal-register">
      <div className="modal-content-register">
        <img
          src={close}
          alt="close"
          className="img-close"
          onClick={props.onClose}
        />
        <div className="image-verify">
          <img src={ntfImage} width="40%" />
        </div>
        <div className="title-verify mt-4">{props.titleContent}</div>
        <div className="title-verify content-verify mt-4">{props.content}</div>
        <div className="d-grid mt-4">
          <Button className="btn-confirm-to-pay" onClick={onClickButton}>
            {props.textButton}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalComponent;
