import axiosClient from "../../utils/axiosClient";

export interface IDataAnalytic {
  totalNfts: number;
  totalCampaign: number;
  totalNftsSold: number;
  totalRevenue: number;
  balance: number;
}

export interface IParamLine {
  filterBy?: FilterBy;
  startDate?: string;
  endDate?: string;
}

export interface IParamLineCircle {
  startTime?: string;
  endTime?: string;
}

export enum FilterBy {
  THIS_MONTH = "THIS_MONTH",
  LAST_MONTH = "LAST_MONTH",
  THIS_YEAR = "THIS_YEAR",
  LAST_YEAR = "LAST_YEAR",
  ALL_TIME = "ALL_TIME",
  CUSTOM_RANGE = "CUSTOM RANGE",
}

export enum FilterByCircle {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  THIS_MONTH = "THIS_MONTH",
  LAST_MONTH = "LAST_MONTH",
  ALL_TIME = "ALL_TIME",
  CUSTOM_RANGE = "CUSTOM RANGE",
}

export const onGetAnalytic = async () => {
  const res = await axiosClient.get("/analytic/company/overview");
  return res;
};

export const onGetAnalyticCollections = async (params: IParamLineCircle) => {
  const res = await axiosClient.get("/analytic/company/collections", params);
  return res;
};

export const onGetDataLineGraph = async (params: IParamLine) => {
  const res = await axiosClient.get("analytic/company/line-graph", params);
  return res;
};

export const onCreateWithDrawRequest = async () => {
  const res = await axiosClient.post("/withdraw-request", null);
  return res;
};
