import React from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const CampaignLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div>
      <div className="title-page color-full">Campaigns</div>
      <Row className="tab-campaign">
        <Col xs={12}>
          <div className="bg-tab-campaign">
            <Tab.Container>
              <div className="border-botton-tab">
                <div className="header-tab">
                  <Nav.Link
                    className={`title-tab ${
                      pathname === "/campaign" ||
                      pathname === "/campaign/running"
                        ? "active-tab"
                        : ""
                    }`}
                    onClick={() => navigate("/campaign/running")}
                  >
                    Active Campaign
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => navigate("/campaign/expired")}
                    className={`title-tab ${
                      pathname === "/campaign/expired" ? "active-tab" : ""
                    }`}
                  >
                    Inactive Campaign
                  </Nav.Link>
                </div>
              </div>
            </Tab.Container>
            <div className="mt-4">
              <Outlet />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CampaignLayout;
