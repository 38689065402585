/* eslint-disable react/style-prop-object */
import { isEmpty } from "lodash";
import React, { useEffect, useRef } from "react";
import { Button, Col, Row, Dropdown } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { useAuth } from "../components/AuthContext";
import AvatarHeader from "../components/AvatarHeader";
import ModalCampaign, { ModalFormRef } from "../components/ModalCampaign";
import { StepProvider } from "../components/StepContext";
import localStorageHelper, { KeyStorage } from "../utils/localStorage";
import { Session } from "../utils/session";
import "react-datepicker/dist/react-datepicker.css";

import {
  DashboardIcon,
  CampaignIcon,
  NftIcon,
  WithdrawNonColorIcon,
} from "../components/Icons";

const LayoutAdmin: React.FC = () => {
  const navigate = useNavigate();
  const modalCampaignRef = useRef<ModalFormRef>(null);

  const auth = useAuth();
  const { pathname } = useLocation();

  useEffect(() => {
    const dataSection: Session = localStorageHelper.getObject(
      KeyStorage.SESSION
    );
    if (isEmpty(dataSection)) {
      navigate("/auth/login", { replace: true });
    }
    modalCampaignRef?.current?.hideModal();
  }, [pathname]);

  const onLogOut = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <button className="btn-close" onClick={onClose}>
              x
            </button>
            <div className="title">Log Out</div>
            <div className="content">Are you sure you want to log off?</div>

            <div className="mt-3" style={{ textAlign: "right" }}>
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  onClose();
                  auth?.logoutAdmin();
                  navigate("/auth/login", { replace: true });
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                variant="danger"
                size="sm"
                onClick={onClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        );
      },
      overlayClassName: "overlayClassName",
      closeOnClickOutside: false,
    });
  };

  return (
    <Row className="menu-layout">
      <Col xs={3} className="bg-menu">
        <div className="layout-cms mb-2">
          <div
            className="oulet-auth cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img src={logo} alt="logo" height={50} />
          </div>
        </div>

        <Link
          to={"dashboard"}
          className={`menu-list text-decoration-none ${
            pathname === "/dashboard" ? "active-menu" : ""
          }`}
        >
          <div className="menu-item">
            <div className="menu-icon">
              <DashboardIcon />
            </div>
            <div className="menu-label">Dashboard</div>
          </div>
        </Link>

        <Link
          to={"campaign"}
          className={`menu-list text-decoration-none 
                    ${
                      pathname === "/campaign" ||
                      pathname === "/campaign/running" ||
                      pathname === "/campaign/expired"
                        ? "active-menu"
                        : ""
                    }`}
        >
          <div className="menu-item">
            <div className="menu-icon">
              <CampaignIcon />
            </div>
            <div className="menu-label">Campaigns</div>
          </div>
        </Link>

        <Link
          to={"nfts"}
          className={`menu-list text-decoration-none ${
            pathname === "/nfts" ? "active-menu" : ""
          }`}
        >
          <div className="menu-item">
            <div className="menu-icon">
              <NftIcon />
            </div>
            <div className="menu-label">NFT Metadata</div>
          </div>
        </Link>

        <Link
          to={"withdraw-request"}
          className={`menu-list text-decoration-none ${
            pathname === "/withdraw-request" ? "active-menu" : ""
          }`}
        >
          <div className="menu-item">
            <div className="menu-icon">
              <WithdrawNonColorIcon />
            </div>
            <div className="menu-label">Withdraw Funds</div>
          </div>
        </Link>
      </Col>
      <Col xs={9} className="p-0">
        <div className="header-cms">
          <Button
            className="btn-create-a-campaign"
            onClick={() => {
              modalCampaignRef?.current?.showModal();
            }}
          >
            Create New Campaign
          </Button>

          <Dropdown>
            <Dropdown.Toggle>
              <AvatarHeader />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#" onClick={onLogOut}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="content-outlet">
          <Outlet />
        </div>

        <StepProvider>
          <ModalCampaign ref={modalCampaignRef} />
        </StepProvider>
      </Col>
    </Row>
  );
};

export default React.memo(LayoutAdmin);
