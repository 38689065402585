import { Alert, Button, Form } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../../../components/ModalComponent";
import { useForm } from "react-hook-form";
import { get, isEmpty } from "lodash";
import { forgotPassword, resetPassword } from "./services";
import { convertCase, useQuery } from "../../../utils/utils";

enum ETypeForgotPassword {
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  SEND_EMAIL = "SEND_EMAIL",
  NEW_PASSWORD = "NEW_PASSWORD",
}

const ForgotPasswordPage: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const [type, setType] = useState<ETypeForgotPassword>(
    ETypeForgotPassword.FORGOT_PASSWORD
  );
  const [visible, setVisible] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>();
  const {
    register: registerForgot,
    handleSubmit: handleSubmitForgot,
    formState: { errors: errorsForgot },
    watch,
  } = useForm<{
    confirm_password?: string;
    password: string;
    token?: string;
  }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [textError, setTextError] = useState<string>();
  const [emailSend, setEmailSend] = useState<string>();
  const [tokenForgotPassword, setTokenForgotPassword] = useState<string>();

  const query = useQuery();
  const token = query.get("token");

  const isRunned = useRef(false);

  useEffect(() => {
    if (!isRunned.current) {
      if (token) {
        onSetTypeForgotPassword(token);
      } else {
        setTokenForgotPassword(undefined);
      }
    }
    return () => {
      isRunned.current = true;
    };
  }, []);

  const onSetTypeForgotPassword = async (token: string) => {
    setTokenForgotPassword(token);
    navigate("/auth/forgot-password", { replace: true });
    setType(ETypeForgotPassword.NEW_PASSWORD);
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    try {
      const params = { ...data };

      const res = await forgotPassword(params);
      switch (res.status) {
        case "VALIDATION_ERROR":
          setTextError(convertCase(get(res, "message")));
          break;
        case "SUCCESS":
          setEmailSend(data.email);
          setTextError(undefined);
          setType(ETypeForgotPassword.SEND_EMAIL);
          break;
        default:
          setTextError(convertCase(res?.status));
          break;
      }

      setLoading(false);
    } catch (error: any) {
      setTextError(error?.message);
      setLoading(false);
    }
  });

  const onSubmitForgot = handleSubmitForgot(async (data) => {
    setLoading(true);
    try {
      const params = { ...data };
      delete params.confirm_password;
      params.token = tokenForgotPassword;

      const res = await resetPassword(params);
      switch (res.status) {
        case "VALIDATION_ERROR":
          setTextError(convertCase(get(res, "message")));
          break;
        case "SUCCESS":
          setVisible(true);
          setTextError(undefined);
          break;
        default:
          setTextError(convertCase(res?.status));
          break;
      }

      setLoading(false);
    } catch (error: any) {
      setTextError(error?.message);
      setLoading(false);
    }
  });

  return (
    <div className="page-recover-password">
      {textError ? (
        <Alert key={"danger"} variant={"danger"}>
          {textError}
        </Alert>
      ) : (
        ""
      )}
      {!isEmpty(errors) ? (
        <Alert key={"danger"} variant={"danger"}>
          {Object.values(errors).map((d, k) => {
            return (
              <div key={`errors-${k + 1}`} className="mb-2">
                {d?.message}
              </div>
            );
          })}
        </Alert>
      ) : (
        ""
      )}

      {!isEmpty(errorsForgot) ? (
        <Alert key={"danger"} variant={"danger"}>
          {Object.values(errorsForgot).map((d, k) => {
            return (
              <div key={`errors-${k + 1}`} className="mb-2">
                {d?.message}
              </div>
            );
          })}
        </Alert>
      ) : (
        ""
      )}
      <div className="sign-up mb-2 color-full">Recover password</div>
      {type === ETypeForgotPassword.FORGOT_PASSWORD ? (
        <>
          <p>Enter your email below to recover your password</p>
          <div className="form-recover-pwd">
            <Form onSubmit={onSubmit}>
              <Form.Group className="mb-2rem">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  {...register("email", {
                    required: {
                      value: true,
                      message: "You must specify a email",
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  })}
                  type="email"
                  placeholder="Enter email address"
                />
              </Form.Group>

              <div className="d-grid">
                <Button
                  className="btn-main mt-1"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <span>
                      <span
                        className="spinner-border spinner-border-sm mright-5"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </span>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
              <div
                className="confirm-email text-center mt-2 cursor-pointer"
                onClick={() => navigate("/auth/login")}
              >
                Back
              </div>
            </Form>
          </div>
        </>
      ) : (
        ""
      )}

      {type === ETypeForgotPassword.SEND_EMAIL ? (
        <>
          <p>
            We have sent you an email at{" "}
            <span>
              <a href={`mailto:${emailSend}`} className="email-send">
                {emailSend}
              </a>
            </span>{" "}
            to recover your password
          </p>
          <div className="d-grid mt-4 form-recover-pwd">
            <Button
              className="btn-main"
              onClick={() => navigate("/auth/login")}
            >
              Got it
            </Button>
            <div
              className="text-center confirm-email mt-3 cursor-pointer"
              onClick={onSubmit}
            >
              Did’t receive email yet?
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {type === ETypeForgotPassword.NEW_PASSWORD ? (
        <>
          <p>Enter new password you like</p>
          <div className="form-recover-pwd">
            <Form onSubmit={onSubmitForgot}>
              <Form.Group className="mb-2rem">
                <Form.Label>Enter password</Form.Label>
                <Form.Control
                  {...registerForgot("password", {
                    required: {
                      value: true,
                      message: "You must specify a password",
                    },
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters",
                    },
                  })}
                  type="password"
                  placeholder="Enter password"
                />
              </Form.Group>

              <Form.Group className="mb-2rem">
                <Form.Label>Confirm password</Form.Label>
                <Form.Control
                  type="password"
                  {...registerForgot("confirm_password", {
                    validate: (val: string | undefined) => {
                      if (watch("password") !== val) {
                        return "Your password does no match";
                      }
                    },
                  })}
                  placeholder="Confirm password"
                />
              </Form.Group>

              <div className="d-grid">
                <Button
                  className="btn-main mt-1"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <span>
                      <span
                        className="spinner-border spinner-border-sm mright-5"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </span>
                  ) : (
                    "Change password"
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </>
      ) : (
        ""
      )}

      <ModalComponent
        visible={visible}
        onClose={() => {
          setVisible(false);
          navigate("/auth/forgot-password", { replace: true });
          setType(ETypeForgotPassword.FORGOT_PASSWORD);
        }}
        titleContent="Recover password"
        content="You have successfully recover your password click below to login"
        textButton="Login"
        to="/auth/login"
      />
    </div>
  );
};

export default ForgotPasswordPage;
