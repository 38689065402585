import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useStepContext } from "../../components/StepContext";
import { IDataMethod, onGetMethod, subscribeCollection } from "./service";

const iconPlus = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.5 6V12M12.5 12V18M12.5 12H18.5M12.5 12H6.5" stroke="#6902C0" />
  </svg>
);

interface Props {
  onCloseModal: () => void;
}

const PaymentMethod: React.FC<Props> = (props) => {
  const [dataMethod, setDataMethod] = useState<IDataMethod[]>([]);
  const { onChangeStep, dataCollection } = useStepContext();
  const [dataCheckPay, setDataCheckPay] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [textError, setTextError] = useState<string>();
  const [loadingMethod, setLoadingMethod] = useState<boolean>(false);

  useEffect(() => {
    onGetDataPaymentMethod();
  }, []);

  const onGetDataPaymentMethod = async () => {
    setLoadingMethod(true);
    try {
      const res = await onGetMethod();
      if (res) {
        setDataMethod(res?.data);
      }
      setLoadingMethod(false);
    } catch (error) {
      setLoadingMethod(false);
    }
  };

  const onChangeData = (d: any) => {
    const params = {
      collectionId: dataCollection?.collectionId,
      stripeBody: {
        paymentMethodId: d?.id,
        customer: d?.customer,
        amount: dataCollection?.amount,
      },
      shouldSavePaymentMethod: true,
    };
    setDataCheckPay(params);
    setTextError("");
  };

  const onSubmitData = async (d: any) => {
    if (isEmpty(dataCheckPay)) {
      return setTextError("Your card was declined or invalid");
    }
    setLoading(true);
    setTextError("");
    try {
      const res = await subscribeCollection(dataCheckPay);
      if (res && res.status === "COLLECTION_NOT_FOUND") {
        setLoading(false);
        return setTextError("Your card was declined or invalid");
      }

      if (res && res.status === "SUCCESS") {
        props.onCloseModal();
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setTextError("Your card was declined or invalid");
    }
  };

  return (
    <>
      {textError ? (
        <Alert key={"danger"} variant={"danger"}>
          {textError}
        </Alert>
      ) : (
        ""
      )}
      <Row className="payment-method mt-4">
        {loadingMethod ? (
          <div className="text-center mt-2 mb-4">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            {dataMethod &&
              dataMethod.length > 0 &&
              dataMethod.map((d, key) => (
                <Col xs={6} key={`key_data_${key + 1}`}>
                  <div className="item custom-radio">
                    <Form.Check
                      type="radio"
                      name="check-radio"
                      onChange={() => onChangeData(d)}
                      label={
                        <>
                          <div className="info-method">
                            <div className="item-method">
                              <div className="name">
                                {String(get(d, "card.brand")).toUpperCase()} -{" "}
                                {get(d, "billing_details.name")}
                              </div>
                            </div>
                          </div>
                          <div className="card-number">
                            •••• •••• •••• {get(d, "card.last4")}
                          </div>
                          <div className="expiry-method">
                            <div className="date">
                              Expiry {get(d, "card.exp_month")}/
                              {get(d, "card.exp_year")}
                            </div>
                            {/* <div className='active'>{iconDelete} <span>Remove</span></div> */}
                          </div>
                        </>
                      }
                    />
                  </div>
                </Col>
              ))}
          </>
        )}
        <Col xs={6}>
          <div className="item">
            <div className="text-method" onClick={() => onChangeStep(4)}>
              <span className="icon-plus">{iconPlus}</span>{" "}
              <span>Add New Payment Method</span>
            </div>
          </div>
        </Col>
      </Row>
      {!isEmpty(dataMethod) ? (
        <div className="btn-right mt-5">
          <Button className="mt-5 btn-confirm-to-pay" onClick={onSubmitData}>
            {loading ? (
              <span>
                <span
                  className="spinner-border spinner-border-sm mright-5"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </span>
            ) : (
              `Pay for campaign $${dataCollection?.amount || 0}`
            )}
          </Button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PaymentMethod;
