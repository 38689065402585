/* eslint-disable jsx-a11y/alt-text */
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useStepContext } from "../../components/StepContext";
import { convertCase } from "../../utils/utils";

const AddPayment = () => {
  const { onChangeStep, dataCollection } = useStepContext();
  const [preview, setPreview] = useState<string>();

  useEffect(() => {
    if (!dataCollection?.fileView) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(dataCollection?.fileView);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [dataCollection]);

  return (
    <Row className="add-payment mt-1">
      <Row className="payment-nft-preview">
        <Col xs={3}>
          <img src={preview} className="logo-payment" />
        </Col>
        <Col xs={9} className="payment-body">
          <div className="title-payment">{dataCollection?.name}</div>
          <div className="desc-payment">
            <div className="d-flex-between info mt-2">
              <div className="expiry-date">
                <span className="opacity">Expiration date: </span>
                {moment(dataCollection?.expiredDate).format("MM/DD/YYYY")}
              </div>
              <div className="expiry-date">
                <span className="opacity">Category:</span>{" "}
                {dataCollection?.category}
              </div>
            </div>
            <div className="d-flex-between info mt-2">
              <div className="expiry-date">
                <span className="opacity">Supply per Promo:</span>{" "}
                {dataCollection?.supplyNfts}
              </div>
              <div className="expiry-date">
                <span className="opacity">Symbol:</span>{" "}
                {dataCollection?.symbol}
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Col xs={12} className="total-payment mt-5">
        <div className="border-total pb-3">
          <div className="text">Total amount due: </div>
          <div className="price color-full">
            ${dataCollection ? dataCollection.amount.toFixed(2) : 0}
          </div>
        </div>
      </Col>

      <div className="btn-right mt-3">
        <Button className="btn-confirm-to-pay" onClick={() => onChangeStep(3)}>
          Payment
        </Button>
      </div>
    </Row>
  );
};

export default AddPayment;
