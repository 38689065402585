import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Button } from "react-bootstrap";
import LayoutCampaign from "../layout/LayoutCampaign";
import AddPayment from "../pages/AddPayment";
import AddPaymentMethod from "../pages/AddPayment/AddPaymentMethod";
import CreateNewCampaign from "../pages/AddPayment/CreateNewCampaign";
import PaymentMethod from "../pages/AddPayment/PaymentMethod";
import { useStepContext } from "./StepContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { confirmAlert } from "react-confirm-alert";
import ModalComponent from "./ModalComponent";

const svgBack = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 19L3 12M3 12L10 5M3 12H21" stroke="#6902C0" strokeWidth="2" />
  </svg>
);

const svgClose = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 6L18 18M6 18L18 6L6 18Z" stroke="#6902C0" strokeWidth="2" />
  </svg>
);

interface Props {
  onClose?(): void;
}
export interface ModalFormRef {
  showModal(): void;
  hideModal(): void;
}

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_KEY_PUBLIC ||
    "pk_test_51Iyf7QIQDu5kGZG8MEu5u4ispEiQOZtmQsCUY3yybWjaKAaASTPQl45Fg7K6pr3O4voKyQVunSfN1qiQnpr9OS8n00HxKsZb5k"
);

const ModalCampaign: ForwardRefRenderFunction<ModalFormRef, Props> = (
  props,
  ref
) => {
  const {
    dataStep,
    onChangeStep,
    visibleCampaign,
    setVisibleCampaign,
    onUpdateDataCollection,
  } = useStepContext();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    window.onbeforeunload = function () {
      if (visibleCampaign) {
        return true;
      }
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [visibleCampaign]);

  useImperativeHandle(ref, () => ({
    showModal: () => {
      setVisibleCampaign(true);
    },
    hideModal: () => {
      setVisibleCampaign(false);
    },
  }));

  const renderChildren = () => {
    switch (dataStep) {
      case 1:
        return <CreateNewCampaign />;
      case 2:
        return <AddPayment />;
      case 3:
        return (
          <PaymentMethod
            onCloseModal={() => {
              onChangeStep(1);
              setVisibleCampaign(false);
              setVisible(true);
              onUpdateDataCollection(undefined);
            }}
          />
        );
      case 4:
        return (
          <Elements stripe={stripePromise}>
            <AddPaymentMethod
              onCloseModal={() => {
                onChangeStep(1);
                setVisibleCampaign(false);
                setVisible(true);
                onUpdateDataCollection(undefined);
              }}
            />
          </Elements>
        );
    }
    return "";
  };

  const renderTitle = () => {
    let title = "";
    switch (dataStep) {
      case 1:
        title = "Create New Campaign";
        break;
      case 2:
        title = "Confirmation";
        break;
      default:
        title = "Add Payment Method";
        break;
    }
    return title;
  };
  const renderSubTitle = () => {
    let title = "";
    switch (dataStep) {
      case 1:
        title = "";
        break;
      case 2:
        title = "Please confirm campaign details below.";
        break;
      default:
        title = "Please select your payment method";
        break;
    }
    return title;
  };

  const onBackStep = (step: number) => {
    if (step > 0) onChangeStep(step - 1);
  };

  const onCloseModal = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <button className="btn-close" onClick={onClose}>
              x
            </button>
            <div className="title">Are you sure?</div>
            <div className="content">Are you sure you want to do this?</div>

            <div className="mt-3" style={{ textAlign: "right" }}>
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  onClose();
                  setVisibleCampaign(false);
                  onUpdateDataCollection(undefined);
                  onChangeStep(1);
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                variant="danger"
                size="sm"
                onClick={onClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        );
      },
      overlayClassName: "overlayClassName",
      closeOnClickOutside: false,
    });
  };

  return (
    <>
      <LayoutCampaign visible={visibleCampaign} setVisible={setVisibleCampaign}>
        <div className="modal-content-campaign">
          {dataStep > 1 ? (
            <div className="svg-back" onClick={() => onBackStep(dataStep)}>
              {svgBack}
            </div>
          ) : (
            ""
          )}

          <div className="svg-close" onClick={onCloseModal}>
            {svgClose}
          </div>
          <div className="form-create-campaign">
            <div className="title">
              <h1 className="color-full">{renderTitle()}</h1>
              <p>{renderSubTitle()}</p>
            </div>
            {renderChildren()}
          </div>
        </div>
      </LayoutCampaign>

      <ModalComponent
        visible={visible}
        onClose={() => setVisible(false)}
        titleContent={
          <span className="color-full" style={{ fontWeight: "800" }}>
            Congratulations
          </span>
        }
        content={
          <span style={{ color: "#6902C0" }}>
            You have successfully run your campaign click below to see your
            campaigns
          </span>
        }
        textButton="Go to campaign"
        to="/campaign"
      />
    </>
  );
};

export default forwardRef(ModalCampaign);
