import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";

interface IProp {
  bg:
    | "Primary"
    | "Secondary"
    | "Primary"
    | "Secondary"
    | "Success"
    | "Danger"
    | "Warning"
    | "Info"
    | "Light"
    | "Dark";
  title: string;
  body: string;
  visible: boolean;
  onClose: () => void;
}

export const ToastMessage = (props: IProp) => {
  const onCloseToast = () => {
    props.onClose();
  };

  return (
    <ToastContainer position="top-end" className="p-3">
      <Toast
        onClose={onCloseToast}
        bg={props.bg.toLowerCase()}
        show={props.visible}
        autohide
      >
        <Toast.Header>
          <strong className="me-auto">{props.title}</strong>
        </Toast.Header>
        <Toast.Body>{props.body}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};
