/* eslint-disable jsx-a11y/alt-text */
import { get, isEmpty } from "lodash";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDropzone, Accept } from "react-dropzone";
import { useStepContext } from "../../components/StepContext";
import useDebounce from "../../hooks/useDebounce";
import {
  convertCase,
  createRandomString,
  dateIsValid,
} from "../../utils/utils";
import { collectionFee, onGetCategories } from "./service";
// import image_default from "../../assets/images/image_default.png";
// @ts-ignore
import DatePicker from "react-datepicker";
import { ImageIcon } from "../../components/Icons";

interface IFormCampaign {
  [anyProps: string]: any;
}

const CreateNewCampaign = () => {
  const { dataStep, onChangeStep, dataCollection, onUpdateDataCollection } =
    useStepContext();
  const { register, handleSubmit, setValue } = useForm<IFormCampaign>();
  const [name, setName] = useState<string>("");
  const debouncedValue = useDebounce<string>(name, 500);
  const [dataSelect, setDataSelect] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [textError, setTextError] = useState<string>();

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState<string>();

  const [startDate, setStartDate] = useState<any>(null);
  const [valueSelect, setValueSelect] = useState<string>();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/gif": [".gif"],
    },
    multiple: false,
    maxFiles: 1,
    onDrop: (acceptedFiles: any) => {
      setSelectedFile(acceptedFiles[0]);
    },
  });

  useEffect(() => {
    if (dataCollection) {
      Object.entries(dataCollection).forEach(([name, value]: any) =>
        setValue(name, value)
      );
      setStartDate(
        new Date(moment(dataCollection?.expiredDate).format("YYYY/MM/DD"))
      );
      setValueSelect(dataCollection?.category);
      setSelectedFile(dataCollection?.fileView);
    }
  }, [dataStep]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    onGetDataCategories();
  }, []);

  const onGetDataCategories = async () => {
    const res = await onGetCategories();
    if (res && res?.status === "SUCCESS") {
      setDataSelect(res?.data);
    } else {
      setDataSelect([]);
    }
  };

  useEffect(() => {
    if (debouncedValue) {
      setValue(
        "symbol",
        `${debouncedValue.substring(0, 4).trim()}_${createRandomString(4)}`
      );
    }
  }, [debouncedValue]);

  const onSubmit = handleSubmit(async (data) => {
    if (isEmpty(data.name)) return setTextError("You must specify a name");
    if (data.name.length > 100)
      return setTextError("Name is too long (Maximum is 100 characters)");
    if (isEmpty(data.nftName))
      return setTextError("You must specify a nft name");
    if (data.nftName <= 3) {
      return setTextError("Nft name must have at least 3 characters");
    }
    if (data.nftName.length > 100)
      return setTextError("Nft name is too long (Maximum is 100 characters)");
    if (isEmpty(data.symbol)) return setTextError("You must specify a symbol");

    if (isEmpty(data.supplyNfts))
      return setTextError("You must specify a Supply of nfts per promo");
    if (Number(data.supplyNfts > 99999999))
      return setTextError(
        "Supply of nfts amount too much (Maximum is 99999999)"
      );
    if (isEmpty(data.royalty) || data.royalty === "SELECT_MENU")
      return setTextError("You must specify a royalty");
    if (isEmpty(data.price)) return setTextError("You must specify a price");
    if (Number(data.price > 99999999))
      return setTextError("Price too much (Maximum is 99999999)");
    if (isEmpty(data.category) || data.category === "SELECT_MENU")
      return setTextError("You must specify a category");

    if (!startDate) {
      return setTextError(
        "You must specify a expiration date format: MM-DD-YYYY"
      );
    }
    if (startDate) {
      if (!dateIsValid(moment(startDate).format("YYYY-MM-DD"))) {
        return setTextError(
          "You must specify a expiration date format: MM-DD-YYYY"
        );
      }
    }
    if (isEmpty(data.description))
      return setTextError("You must specify a description");
    if (data.description.length > 5000) {
      return setTextError(
        "Description is too long (Maximum is 5000 characters)"
      );
    }

    if (isEmpty(selectedFile)) {
      return setTextError("You must specify a nft image");
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("nftName", data.nftName);
      formData.append("symbol", data.symbol);
      formData.append("royalty", data.royalty);
      formData.append("supplyNfts", data.supplyNfts);
      formData.append("price", data.price);
      formData.append("category", data.category);
      formData.append("file", selectedFile!);
      formData.append("expiredDate", String(new Date(startDate)));
      formData.append("description", data.description);

      const res = await collectionFee(formData);

      if (res && res?.status) {
        switch (res.status) {
          case "VALIDATION_ERROR":
            setTextError(convertCase(get(res, "message")));
            break;
          case "SUCCESS":
            const dataStep = { ...data };
            dataStep.fileView = selectedFile;
            dataStep.collectionId = res?.collectionId;
            dataStep.amount = res?.amount;
            dataStep.expiredDate = String(moment(startDate));
            setTextError("");
            // Update Data Context
            onUpdateDataCollection(dataStep);
            onChangeStep(2);
            break;
          default:
            setTextError(convertCase(get(res, "status")));
            break;
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    setName(event.target.value);

  return (
    <>
      {textError ? (
        <Alert className="mt-4" key={"danger"} variant={"danger"}>
          {textError}
        </Alert>
      ) : (
        ""
      )}
      <Form className="mt-4" onSubmit={onSubmit}>
        <Row>
          <Col sx={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Campaign name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control {...register("name")} placeholder="Campaign name" />
            </Form.Group>
          </Col>
          <Col sx={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Name of Product/Coupon <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                {...register("nftName")}
                placeholder="Name of Product/Coupon"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sx={6}>
            <Form.Group className="mb-3">
              <Form.Label>Symbol</Form.Label>
              <Form.Control
                disabled
                {...register("symbol")}
                placeholder="Symbol"
              />
            </Form.Group>
          </Col>
          <Col sx={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Category <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Select
                placeholder="Category"
                value={valueSelect}
                {...register("category")}
              >
                <option className="sl-cp" disabled selected value="SELECT_MENU">
                  Select Category
                </option>
                {dataSelect &&
                  dataSelect.length > 0 &&
                  dataSelect.map((d: any, i: number) => {
                    return (
                      <option value={d.name} key={`cate_${i + 1}`}>
                        {d.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sx={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Price per Product/Coupon <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                {...register("price")}
                type="number"
                placeholder="Price per Product/Coupon"
              />
            </Form.Group>
          </Col>
          <Col sx={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Supply <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                {...register("supplyNfts")}
                type="number"
                placeholder="Supply"
              />
            </Form.Group>
          </Col>
          <Col sx={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Royalty <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Select {...register("royalty")} placeholder="Royalty">
                <option disabled selected className="sl-cp" value="SELECT_MENU">
                  Select Royalty
                </option>
                <option value="0">0</option>
                <option value="2">2</option>
                <option value="5">5</option>
                <option value="10">10</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Upload image <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <div {...getRootProps({ className: "dropzone d-create-file" })}>
                {selectedFile ? (
                  <img src={preview} className="img-preview" />
                ) : (
                  <>
                    <ImageIcon />
                    <div className="browse">
                      <input {...getInputProps()} />
                      <p style={{ cursor: "pointer" }}>Drag image or browser</p>
                    </div>
                  </>
                )}
              </div>
            </Form.Group>
          </Col>

          <Col sx={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Expiration date <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <DatePicker
                selected={startDate}
                onChange={(date: any) => setStartDate(date)}
                minDate={new Date()}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className="form-control date-picker-input"
                placeholderText="MM/DD/YYYY"
                isClearable
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                Description <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                {...register("description")}
                rows={4}
                placeholder="Description"
              />
            </Form.Group>
          </Col>
        </Row>

        <div className="btn-right">
          <Button
            className="mt-3 btn-create-campaign"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <span>
                <span
                  className="spinner-border spinner-border-sm mright-5"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </span>
            ) : (
              "Pay for Campaign"
            )}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CreateNewCampaign;
