import React, { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../components/AuthContext";
import { loginAdmin } from "./services";

export interface IFormLogin {
  email: string;
  password: string;
  remember?: boolean;
}

const LoginPage: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const auth = useAuth();
  const [textError, setTextError] = useState<string>();

  const { register, handleSubmit } = useForm<IFormLogin>();

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    try {
      const params = { ...data };
      delete params.remember;
      const res = await loginAdmin(params);
      if (res && res.status) {
        setLoading(false);
        switch (res.status) {
          case "ACCOUNT_IS_INACTIVE":
            setTextError(
              "Your account is not approved by Koopon. Please wait for approval"
            );
            break;
          case "ACCOUNT_IS_NOT_VERIFIED":
            setTextError(
              "Your account is not verified. Let verify your email."
            );
            break;
          case "SUCCESS":
            auth?.loginAdmin(res);
            navigate("/", { replace: true });
            break;
          default:
            setTextError("Email or password is incorrect");
            break;
        }
      }
    } catch (error) {
      setLoading(false);
    }
  });

  return (
    <div className="page-login">
      {textError ? (
        <Alert key={"danger"} variant={"danger"}>
          {textError}
        </Alert>
      ) : (
        ""
      )}
      <div className="sign-up mb-4 color-full">Log In</div>
      <div className="form-login">
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              {...register("email")}
              placeholder="Enter email address"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              {...register("password")}
              placeholder="Enter password"
            />
          </Form.Group>
          {/* <Form.Group className="mb-3 footer-login">
            <Form.Check
              type="checkbox"
              {...register("remember")}
              label="Remember Me"
              defaultChecked
            />
          </Form.Group> */}
          <div className="d-grid">
            <Button className="btn-main mt-3" type="submit" disabled={loading}>
              {loading ? (
                <span>
                  <span
                    className="spinner-border spinner-border-sm mright-5"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </span>
              ) : (
                "Sign In"
              )}
            </Button>
          </div>
          <div
            className="text-login cursor-pointer"
            onClick={() => {
              navigate("/auth/forgot-password");
            }}
          >
            Forgot Password
          </div>
          <hr style={{ color: "#D9D9D9" }} />
          <div className="account mt-4 text-center">
            <b className="regsiter-company cursor-pointer">New Brand?</b>
            <Button
              onClick={() => {
                navigate("/auth/register");
              }}
              className="mt-2 btn-go-sign-up"
              type="button"
            >
              Sign Up
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;
