import axiosClient from "../../../utils/axiosClient";

export const forgotPassword = async (data: { email: string }) => {
  const res = await axiosClient.post("/auth/company/forgot-password", data);
  return res;
};

export const resetPassword = async (data: {
  token?: string;
  password: string;
}) => {
  const res = await axiosClient.post("/auth/company/reset-password", data);
  return res;
};
