import { IFormRegister } from ".";
import axiosClient from "../../../utils/axiosClient";

export const registerCompany = async (data: IFormRegister) => {
  const res = await axiosClient.post("/auth/company/register", data);
  return res;
};

export const verifyAccount = async (data: { verifyToken: string }) => {
  const res = await axiosClient.post("/auth/company/verify-account", data);
  return res;
};
