import { merge } from 'lodash';
import moment from 'moment';
import axiosClient from './axiosClient';
import localStorageHelper, { KeyStorage } from './localStorage';

export type Session = {
    data: { _id: string, name: string, email: string }
    expiresAt: string
    refreshToken: string
    status: string
    token: string
};

export const setSession = (session: Session | null) => {
    localStorageHelper.setObject(KeyStorage.SESSION, session);
};

export const getSession = async () => {
    try {
        const session = localStorageHelper.getObject<Session | null>(KeyStorage.SESSION, null);
        if (session) {
            return session as Session;
        }
    } catch (error) { }
    setSession(null);
    return null;
};
