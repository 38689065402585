import React, { useState, useEffect } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { onGetDataMyNfts } from "./service";

const NftPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleGetMyNfts = async () => {
    setLoading(true);
    const res = await onGetDataMyNfts();
    if (res && res.data.length) {
      setData(res.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetMyNfts();
  }, []);

  return (
    <div>
      <div className="title-page color-full">Nft Metadata</div>
      {loading ? (
        <div className="text-center mt-4">
          <Spinner animation="border" />
        </div>
      ) : (
        <Row>
          <Col xs={12}>
            <Table striped bordered hover>
              <thead>
                <tr className="text-center">
                  <th>#</th>
                  <th>Symbol</th>
                  <th>Address</th>
                  <th>Name of Product/Coupon</th>
                  <th>Price</th>
                  <th>Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((item: any, index: number) => {
                    return (
                      <tr key={index} className="text-center">
                        <td>{index + 1}</td>
                        <td
                          style={{
                            width: "50px",
                            overflowX: "auto",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          {item[2]}
                        </td>

                        <td
                          style={{
                            width: "150px",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          <code>{item[3]}</code>
                        </td>
                        <td
                          style={{
                            fontSize: "13px",
                            fontWeight: "400",
                            width: "450px",
                            wordBreak: "break-word",
                          }}
                        >
                          {item[1]}
                        </td>
                        <td
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                        >
                          {item[6] && Number(item[6]) > 0 ? item[6] : "Free"}
                        </td>
                        <td
                          style={{
                            width: "100px",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          {item[7]}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default NftPage;
