import axiosClient from "../../utils/axiosClient";

export interface IDataCollectionFee {
  [anyProps: string]: any;
}

export interface IDataMethod {
  [anyProps: string]: any;
}

export interface IDatasubscribecollection {
  [anyProps: string]: any;
}

export const onGetCategories = async () => {
  const res = await axiosClient.get("/categories");
  return res;
};

export const collectionFee = async (data: any) => {
  const res = await axiosClient.postFormData(
    "/collection/calculate-collection-fee",
    data
  );
  return res;
};

export const onGetMethod = async () => {
  const res = await axiosClient.get("/payment/stripe/list-payment-method", {
    type: "card",
  });
  return res;
};

export const subscribeCollection = async (data: IDatasubscribecollection) => {
  const res = await axiosClient.post(
    "payment/stripe/company-subscribe-collection",
    data
  );
  return res;
};
