import ReactECharts from "echarts-for-react";
import { get, isEmpty, map } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Row,
  Spinner,
  Modal,
  Button,
  Badge,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { convertCase } from "../../utils/utils";
import {
  ExpiredStatus,
  getMyCollection,
  IDataCollection,
} from "../Campaign/service";
import CampaignDashboard from "./components/Campaign";
import {
  FilterBy,
  FilterByCircle,
  IDataAnalytic,
  IParamLine,
  IParamLineCircle,
  onGetAnalytic,
  onGetAnalyticCollections,
  onGetDataLineGraph,
  onCreateWithDrawRequest,
} from "./services";
import { onCountPendingWithdrawRequest } from "../Withdraw/service";
// @ts-ignore
import DatePicker from "react-datepicker";
import { WithdrawColorIcon } from "../../components/Icons";
import { ToastMessage } from "../../components/ToastMessage";

interface IDataLine {
  [anyProps: string]: any;
}

const defaultData = {
  totalNfts: 0,
  totalCampaign: 0,
  totalNftsSold: 0,
  totalRevenue: 0,
  balance: 0,
};

const formatMoment = "YYYY-MM-DD";

const DashboardPage: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const [loadingRunning, setLoadingRunning] = useState<boolean>(false);
  const [loadingExpired, setLoadingExpired] = useState<boolean>(false);

  const [dataAnalytic, setDataAnalytic] = useState<IDataAnalytic>(defaultData);

  const [dataRunning, setDataRunning] = useState<IDataCollection[]>([]);
  const [dataExpired, setDataExpired] = useState<IDataCollection[]>([]);

  const [paramLine, setParamLine] = useState<IParamLine>({
    filterBy: FilterBy.THIS_MONTH,
    startDate: undefined,
    endDate: undefined,
  });
  const [dataLine, setDataLine] = useState<IDataLine[]>([]);
  const [loadingLine, setLoadingLine] = useState<boolean>(false);

  const [paramLineCircle, setParamLineCircle] = useState<IParamLineCircle>({
    startTime: undefined,
    endTime: undefined,
  });
  const [dataLineCircle, setDataLineCircle] = useState<IDataLine>({
    totalNftsSold: 0,
    totalNfts: 0,
  });
  const [loadingCircle, setLoadingCircle] = useState<boolean>(false);

  // Date Line
  const [showDateLine, setShowDateLine] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  // Date Line Circle
  const [showDateLineCircle, setShowDateLineCircle] = useState<boolean>(false);
  const [dateRangeCircle, setDateRangeCircle] = useState([null, null]);
  const [startDateCircle, endDateCircle] = dateRangeCircle;
  const [showModal, setShowModal] = useState(false);
  const [loadingModalButton, setLoadingModalButton] = useState(false);
  const [toastOptions, setToastOptions] = useState({
    bg: "Light" as any,
    title: "",
    body: "",
    visible: false,
  });
  const [pendingWithdrawRequest, setPendingWithdrawRequest] =
    useState<number>(0);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => {
    if (pendingWithdrawRequest > 0) return;
    setShowModal(true);
  };

  const handleCreateWithDrawRequest = async () => {
    setLoadingModalButton(true);
    const res = await onCreateWithDrawRequest();
    if (res && res.data) {
      setTimeout(() => {
        setShowModal(false);
        setLoadingModalButton(false);
        setToastOptions({
          bg: "Info",
          title: "Create Successfully",
          body: "Woohoo, you created a new withdraw request!",
          visible: true,
        });
        navigate("/withdraw-request");
      }, 3000);
    } else {
      let message = "Hmm, something went wrong!";
      if (res.status === "ONLY_ONE_REQUEST_CAN_BE_CREATED") {
        message = "Can not create more withdraw request!";
      } else if (res.status === "NOT_ENOUGH_AMOUNT") {
        message = "Not enough balance amount!";
      }
      setTimeout(() => {
        setShowModal(false);
        setLoadingModalButton(false);
        setToastOptions({
          bg: "Warning",
          title: "Create Fail",
          body: message,
          visible: true,
        });
        onGetDataAnalytic();
      }, 3000);
    }
  };

  const handleCountPendingWithdrawRequest = async () => {
    const res = await onCountPendingWithdrawRequest();
    if (res && res.data) {
      setPendingWithdrawRequest(res.data);
    }
  };

  const onSelect = (value: any) => {
    if (value.target.value === FilterBy.CUSTOM_RANGE) {
      setShowDateLine(true);
    } else {
      setDateRange([null, null]);
      setShowDateLine(false);
      setParamLine({
        ...paramLine,
        filterBy: value.target.value,
        startDate: undefined,
        endDate: undefined,
      });
    }
  };

  const onSelectCircle = (value: any) => {
    let params: IParamLineCircle = {
      startTime: undefined,
      endTime: undefined,
    };
    const valueLine = value.target.value;

    if (valueLine === FilterByCircle.CUSTOM_RANGE) {
      setShowDateLineCircle(true);
    } else {
      setShowDateLineCircle(false);
      setDateRangeCircle([null, null]);

      if (valueLine === FilterByCircle.TODAY) {
        params = {
          startTime: String(moment().format(formatMoment)),
          endTime: String(moment().format(formatMoment)),
        };
      } else if (valueLine === FilterByCircle.YESTERDAY) {
        params = {
          startTime: String(moment().subtract(1, "day").format(formatMoment)),
          endTime: String(moment().subtract(1, "day").format(formatMoment)),
        };
      } else if (valueLine === FilterByCircle.THIS_MONTH) {
        params = {
          startTime: String(moment().startOf("month").format(formatMoment)),
          endTime: String(moment().endOf("month").format(formatMoment)),
        };
      } else if (valueLine === FilterByCircle.LAST_MONTH) {
        params = {
          startTime: String(
            moment().subtract(1, "months").startOf("month").format(formatMoment)
          ),
          endTime: String(
            moment().subtract(1, "months").endOf("month").format(formatMoment)
          ),
        };
      } else if (valueLine === FilterByCircle.ALL_TIME) {
        params = {
          startTime: undefined,
          endTime: undefined,
        };
      }
      setParamLineCircle(params);
    }
  };

  useEffect(() => {
    onGetDataAnalyticCollections(paramLineCircle);
  }, [paramLineCircle]);

  const onGetDataAnalyticCollections = async (
    paramLineCircle: IParamLineCircle
  ) => {
    setLoadingCircle(true);
    try {
      const res = await onGetAnalyticCollections(paramLineCircle);
      if (res && res.data) {
        setDataLineCircle({
          totalNftsSold: get(res, "data.totalNftsSold") || 0,
          totalNfts: get(res, "data.totalNfts") || 0,
        });
        setLoadingCircle(false);
      } else {
        setLoadingCircle(false);
      }
    } catch (error) {
      setLoadingCircle(false);
    }
  };

  useEffect(() => {
    onGetDataMyCollection();
    onGetDataMyCollectionExpired();
    onGetDataAnalytic();
    handleCountPendingWithdrawRequest();
  }, []);

  useEffect(() => {
    onGetLineGraph(paramLine);
  }, [paramLine]);

  const onGetDataAnalytic = async () => {
    try {
      const res = await onGetAnalytic();
      if (res && res.status === "SUCCESS") {
        setDataAnalytic(res.data);
      } else {
        setDataAnalytic(defaultData);
      }
    } catch (error) {
      setDataAnalytic(defaultData);
    }
  };

  const onGetDataMyCollection = async () => {
    setLoadingRunning(true);
    try {
      const res = await getMyCollection({
        limit: 5,
        page: 1,
        expiredStatus: ExpiredStatus.RUNNING,
      });
      setDataRunning(res?.data);
      setLoadingRunning(false);
    } catch (error) {
      setDataRunning([]);
      setLoadingRunning(false);
    }
  };

  const onGetDataMyCollectionExpired = async () => {
    setLoadingExpired(true);
    try {
      const res = await getMyCollection({
        limit: 5,
        page: 1,
        expiredStatus: ExpiredStatus.EXPIRED,
      });
      setDataExpired(res?.data);
      setLoadingExpired(false);
    } catch (error) {
      setDataExpired([]);
      setLoadingExpired(false);
    }
  };

  const onGetLineGraph = async (paramLine: IParamLine) => {
    setLoadingLine(true);
    try {
      const res = await onGetDataLineGraph(paramLine);
      if (res && res.data) {
        setDataLine(res.data);
        setLoadingLine(false);
      } else {
        setDataLine([]);
        setLoadingLine(false);
      }
    } catch (error) {
      setDataLine([]);
      setLoadingLine(false);
    }
  };

  const option = {
    tooltip: {},
    legend: {
      show: false,
    },
    colorBy: "series",
    color: ["#CD11EC"],
    xAxis: {
      type: "category",
      data: !isEmpty(dataLine) ? map(dataLine, "time") : [],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Sales Graph",
        type: "line",
        smooth: true,
        data: !isEmpty(dataLine) ? map(dataLine, "total") : [],
      },
    ],
  };

  const optionEvent = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : <b>{c}</b>",
    },
    colorBy: "series",
    color: ["#CD11EC", "#6902C0"],
    legend: {
      left: "left",
      bottom: 50,
      orient: "horizontal",
      data: ["Distributed", "Sold"],
    },
    series: [
      {
        name: "Campaign",
        type: "pie",
        radius: "55%",
        center: ["50%", "40%"],
        data: [
          { value: dataLineCircle?.totalNfts, name: "Distributed" },
          { value: dataLineCircle?.totalNftsSold, name: "Sold" },
        ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  return (
    <div>
      <div className="title-page color-full">Dashboard</div>
      <Row className="analytic-section">
        <Col md={2} className="analytic-block">
          <div className="box-data">
            <div className="title-total">Total Distributed</div>
            <div className="value-total">{dataAnalytic.totalNfts}</div>
          </div>
          <div className="box-data">
            <div className="title-total">Total Sold</div>
            <div className="value-total">{dataAnalytic.totalNftsSold}</div>
          </div>
          <div className="box-data">
            <div className="title-total">Total Promos</div>
            <div className="value-total">{dataAnalytic.totalCampaign}</div>
          </div>
          <div className="box-data">
            <div className="title-total">Total Revenue</div>
            <div className="value-total">${dataAnalytic.totalRevenue}</div>
          </div>
          <div className="box-data">
            <div className="title-total balance">
              <span>Balance</span>
              <Button className="withdraw-btn" onClick={handleShowModal}>
                Withdraw
                {pendingWithdrawRequest > 0 && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">
                        {pendingWithdrawRequest} pending withdraw request
                      </Tooltip>
                    }
                  >
                    <Badge
                      pill
                      bg="warning"
                      text="dark"
                      style={{
                        position: "absolute",
                        right: "-10px",
                        top: "-12px",
                      }}
                    >
                      {pendingWithdrawRequest}
                    </Badge>
                  </OverlayTrigger>
                )}
              </Button>
            </div>
            <div className="value-total">
              ${dataAnalytic.balance.toFixed(2)}
            </div>
          </div>
        </Col>

        <Col md={5}>
          <div className="bg-line">
            <div className="title-analytic">
              <div>Sales Graph</div>
              <div style={{ width: 200 }}>
                <Form.Group>
                  <Form.Select
                    style={{ marginBottom: 0 }}
                    onChange={(value: any) => onSelect(value)}
                  >
                    <option value={FilterBy.THIS_MONTH}>
                      {convertCase(FilterBy.THIS_MONTH)}
                    </option>
                    <option value={FilterBy.LAST_MONTH}>
                      {convertCase(FilterBy.LAST_MONTH)}
                    </option>
                    <option value={FilterBy.THIS_YEAR}>
                      {convertCase(FilterBy.THIS_YEAR)}
                    </option>
                    <option value={FilterBy.LAST_YEAR}>
                      {convertCase(FilterBy.LAST_YEAR)}
                    </option>
                    <option value={FilterBy.ALL_TIME}>
                      {convertCase(FilterBy.ALL_TIME)}
                    </option>
                    <option value={FilterBy.CUSTOM_RANGE}>
                      {convertCase(FilterBy.CUSTOM_RANGE)}
                    </option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
            {showDateLine ? (
              <div style={{ marginTop: 10 }}>
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update: any) => {
                    setDateRange(update);
                    if (update[0] !== null && update[1] !== null) {
                      setParamLine({
                        ...paramLine,
                        filterBy: undefined,
                        startDate: moment(update[0]).format(formatMoment),
                        endDate: moment(update[1]).format(formatMoment),
                      });
                    }
                  }}
                  withPortal
                  // isClearable
                  className="form-control date-picker-input"
                  placeholderText="MM/DD/YYYY - MM/DD/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            ) : (
              ""
            )}
            <div>
              {loadingLine ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" />
                </div>
              ) : (
                <ReactECharts option={option} style={{ height: 350 }} />
              )}
            </div>
          </div>
        </Col>

        <Col md={5} className="bg-line">
          <div className="title-analytic">
            <div>Campaign</div>
            <div style={{ width: 200 }}>
              <Form.Group>
                <Form.Select
                  defaultValue={FilterByCircle.ALL_TIME}
                  style={{ marginBottom: 0 }}
                  onChange={(value: any) => onSelectCircle(value)}
                >
                  <option value={FilterByCircle.TODAY}>
                    {convertCase(FilterByCircle.TODAY)}
                  </option>
                  <option value={FilterByCircle.YESTERDAY}>
                    {convertCase(FilterByCircle.YESTERDAY)}
                  </option>
                  <option value={FilterByCircle.THIS_MONTH}>
                    {convertCase(FilterByCircle.THIS_MONTH)}
                  </option>
                  <option value={FilterByCircle.LAST_MONTH}>
                    {convertCase(FilterByCircle.LAST_MONTH)}
                  </option>
                  <option value={FilterByCircle.ALL_TIME}>
                    {convertCase(FilterByCircle.ALL_TIME)}
                  </option>
                  <option value={FilterByCircle.CUSTOM_RANGE}>
                    {convertCase(FilterByCircle.CUSTOM_RANGE)}
                  </option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>
          {showDateLineCircle ? (
            <div style={{ marginTop: 10 }}>
              <DatePicker
                selectsRange={true}
                startDate={startDateCircle}
                endDate={endDateCircle}
                onChange={(update: any) => {
                  setDateRangeCircle(update);
                  if (update[0] !== null && update[1] !== null) {
                    setParamLineCircle({
                      ...paramLineCircle,
                      startTime: moment(update[0]).format(formatMoment),
                      endTime: moment(update[1]).format(formatMoment),
                    });
                  }
                }}
                withPortal
                // isClearable
                className="form-control date-picker-input"
                placeholderText="MM/DD/YYYY - MM/DD/YYYY"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          ) : (
            ""
          )}

          {loadingCircle ? (
            <div className="text-center mt-4">
              <Spinner animation="border" />
            </div>
          ) : (
            <ReactECharts option={optionEvent} style={{ height: 350 }} />
          )}
        </Col>
      </Row>
      {/* Recent Compaign */}
      <Row
        className="mt-3"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Col md={6} className="list-content">
          <CampaignDashboard
            title="Active Campaign"
            data={dataRunning}
            to="/campaign/running"
            loading={loadingRunning}
          />
        </Col>

        <Col md={6} className="list-content">
          <CampaignDashboard
            title="Inactive Campaign"
            data={dataExpired}
            to="/campaign/expired"
            loading={loadingExpired}
          />
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Withdraw Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingModalButton ? (
            <div className="text-center">
              <Spinner animation="border" />
            </div>
          ) : (
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              <WithdrawColorIcon width={20} height={20} />
              Woohoo, confirming to create a new withdraw request!
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={handleCloseModal}>
            No
          </Button>

          <Button
            size="sm"
            variant="success"
            disabled={loadingModalButton}
            onClick={handleCreateWithDrawRequest}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastMessage
        bg={toastOptions.bg}
        title={toastOptions.title}
        body={toastOptions.body}
        visible={toastOptions.visible}
        onClose={() =>
          setToastOptions({
            ...toastOptions,
            visible: false,
          })
        }
      />
    </div>
  );
};

export default DashboardPage;
