import { get, isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../../../components/ModalComponent";
import { convertCase, useQuery } from "../../../utils/utils";
import { registerCompany, verifyAccount } from "./services";

export interface IFormRegister {
  email: string;
  name: string;
  password: string;
  confirm_password?: string;
}

const RegisterPage: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<IFormRegister>();
  const [textError, setTextError] = useState<string>();
  const [mailTo, setMailTo] = useState<string>();
  const [typeModal, setTypeModal] = useState<string>("VERIFY_EMAIL");

  const query = useQuery();
  const token = query.get("token");

  const isRunned = useRef(false);

  useEffect(() => {
    if (!isRunned.current) {
      if (token) {
        onVerifyToken(token);
      }
    }
    return () => {
      isRunned.current = true;
    };
  }, []);

  const onVerifyToken = async (token: string) => {
    try {
      const res = await verifyAccount({
        verifyToken: token,
      });
      if (res && res?.status) {
        if (res.status === "VERIFY_TOKEN_INVALID") {
          setTextError(convertCase(get(res, "status")));
          navigate("/auth/register", { replace: true });
        }

        if (res.status === "SUCCESS") {
          setTypeModal("VERIFY_EMAIL_SUCCESS");
          setVisible(true);
        }
      }
    } catch (error) {}
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    try {
      const params = { ...data };
      delete params.confirm_password;
      const res = await registerCompany(params);
      if (res && res?.status) {
        switch (res.status) {
          case "VALIDATION_ERROR":
            setVisible(false);
            setTextError(convertCase(get(res, "message")));
            break;
          case "SUCCESS":
            reset();
            setTextError(undefined);
            setMailTo(`mailto:${params.email}`);
            setVisible(true);
            break;
          default:
            setTextError(convertCase(res?.status));
            break;
        }
      }
      setLoading(false);
    } catch (error: any) {
      setTextError(error?.message);
      setLoading(false);
    }
  });

  return (
    <>
      {textError ? (
        <Alert key={"danger"} variant={"danger"}>
          {textError}
        </Alert>
      ) : (
        ""
      )}
      {!isEmpty(errors) ? (
        <Alert key={"danger"} variant={"danger"}>
          {Object.values(errors).map((d, k) => {
            return (
              <div key={`errors-${k + 1}`} className="mb-2">
                {d?.message}
              </div>
            );
          })}
        </Alert>
      ) : (
        ""
      )}

      <div className="page-register">
        <div className="sign-up mb-2 color-full">Sign Up</div>
        <div className="form-register">
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-1">
              <Form.Label>Company name</Form.Label>
              <Form.Control
                {...register("name", {
                  required: {
                    value: true,
                    message: "You must specify a company name",
                  },
                  minLength: {
                    value: 2,
                    message: "Company name must have at least 2 characters",
                  },
                })}
                placeholder="Company name"
              />
            </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label>Company email</Form.Label>
              <Form.Control
                {...register("email", {
                  required: {
                    value: true,
                    message: "You must specify a company email",
                  },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format",
                  },
                })}
                placeholder="Company email"
                type="email"
              />
            </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label>Password</Form.Label>
              <Form.Control
                {...register("password", {
                  required: {
                    value: true,
                    message: "You must specify a password",
                  },
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                })}
                type="password"
                placeholder="Password"
              />
            </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label>Confirm password</Form.Label>
              <Form.Control
                type="password"
                {...register("confirm_password", {
                  validate: (val: string | undefined) => {
                    if (watch("password") !== val) {
                      return "Your password does not match";
                    }
                  },
                })}
                placeholder="Confirm password"
              />
            </Form.Group>

            <div className="d-grid">
              <Button
                className="btn-main mt-1"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <span>
                    <span
                      className="spinner-border spinner-border-sm m-right-5"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </span>
                ) : (
                  "Submit"
                )}
              </Button>
            </div>

            <div className="account mt-4 text-center">
              Already have an account?{" "}
              <b
                className="regsiter-company cursor-pointer"
                onClick={() => {
                  navigate("/auth/login");
                }}
              >
                Log in
              </b>
            </div>
          </Form>
        </div>

        <ModalComponent
          visible={visible}
          onClose={() => {
            setVisible(false);
            navigate("/auth/register", { replace: true });
          }}
          titleContent={
            typeModal === "VERIFY_EMAIL"
              ? "Verify Your Email"
              : "Thanks for registration"
          }
          content={
            typeModal === "VERIFY_EMAIL"
              ? "We have sent you a verification email and an account representative will be in touch shortly."
              : "Your Request is under process. As your request approved you will receive email and then log in."
          }
          textButton={typeModal === "VERIFY_EMAIL" ? "Close" : "Log In"}
          to={typeModal === "VERIFY_EMAIL" ? "/auth/login" : "/auth/login"}
          targetBlank={false}
        />
      </div>
    </>
  );
};

export default RegisterPage;
