/* eslint-disable jsx-a11y/alt-text */
import { Col, Row, Spinner } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IDataCollection } from "../../Campaign/service";
import imageItem from "../../../assets/images/image_item.png";
import { get, isEmpty } from "lodash";
import moment from "moment";

interface Props {
  title: string;
  data: IDataCollection[];
  to: string;
  loading: boolean;
}

const CampaignDashboard: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  return (
    <div className="bg-content">
      <div className="header-compaign">
        <div className="title">{props.title}</div>
        {props.data && props.data.length > 0 ? (
          <div
            className="view-all cursor-pointer"
            onClick={() => navigate(props.to)}
          >
            View all
          </div>
        ) : (
          ""
        )}
      </div>

      {props.loading ? (
        <div className="text-center mt-4">
          <Spinner animation="border" />
        </div>
      ) : isEmpty(props.data) ? (
        <div className="border-top-list">No data</div>
      ) : (
        props.data.map((d: IDataCollection, key: number) => {
          return (
            <div className="border-top-list" key={`data_key_${key + 1}`}>
              <Row>
                <Col xs={3}>
                  <img
                    src={get(d, "avatar.image") || imageItem}
                    className="image-compaign"
                  />
                </Col>
                <Col xs={9}>
                  <div className="box-content-dashboard">
                    <div className="expiry-date">
                      <div>
                        <span className="opacity">Expires</span> •{" "}
                        {moment(d.expiredDate).format("MM/DD/YYYY")}
                      </div>
                      <span className="sold">
                        Sold: {d.totalSold}/{d.supplyNfts}
                      </span>
                    </div>
                    <div className="ntf-compaign">
                      <div>{d.name || d.nftName}</div>
                      <div className="expired-sold-out">
                        {d.soldOut ? (
                          <div className="sold-out">SOLD OUT</div>
                        ) : (
                          ""
                        )}
                        {d.isExpired ? (
                          <div className="sold-out">EXPIRED</div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div
                      className="compaign-content"
                      dangerouslySetInnerHTML={{ __html: d.description }}
                    ></div>
                  </div>
                </Col>
              </Row>
            </div>
          );
        })
      )}
    </div>
  );
};

export default CampaignDashboard;
