import axiosClient from "../../utils/axiosClient";

export enum WithdrawStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  REJECT = "REJECT",
  CANCELED = "CANCELED",
}
export interface WithdrawQueryInput {
  page: number;
  limit: number;
  status: WithdrawStatus[];
}

export interface WithdrawRequest {
  _id: string;
  createdBy: string;
  amount: number;
  status: WithdrawStatus;
  rejectedAt: Date;
  acceptedAt: Date;
  createdAt: Date;
  deletedAt: Date;
  updatedAt: Date;
}

export const onGetWithdrawRequestList = async (params: WithdrawQueryInput) => {
  return await axiosClient.get("withdraw-request/mine", params);
};

export const onCancelWithdrawRequest = async (_id: string) => {
  return await axiosClient.put(`/withdraw-request/cancel/${_id}`, null);
};

export const onCountPendingWithdrawRequest = async () => {
  return await axiosClient.get("withdraw-request/my-pending");
};
