import React from "react";

export const NftIcon = ({ width = 20, height = 20 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 256 256"
      xmlSpace="preserve"
    >
      <defs></defs>
      <g
        style={{
          stroke: "none",
          strokeWidth: 0,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          fill: "none",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
      >
        <path
          d="M 45 67.03 c -1.152 0 -2.305 -0.297 -3.332 -0.89 L 27.59 58.011 c -2.055 -1.187 -3.332 -3.398 -3.332 -5.771 V 35.983 c 0 -2.373 1.277 -4.585 3.332 -5.772 l 14.078 -8.128 c 2.055 -1.186 4.608 -1.187 6.665 0 l 14.079 8.128 c 2.055 1.187 3.331 3.398 3.331 5.772 V 52.24 c 0 2.373 -1.276 4.584 -3.331 5.771 L 48.332 66.14 C 47.305 66.733 46.152 67.03 45 67.03 z M 45 23.193 c -0.807 0 -1.613 0.208 -2.332 0.623 L 28.59 31.944 c -1.439 0.83 -2.332 2.378 -2.332 4.039 V 52.24 c 0 1.66 0.894 3.208 2.332 4.039 l 14.078 8.129 c 1.438 0.829 3.224 0.829 4.665 0 l 14.079 -8.129 c 1.438 -0.83 2.331 -2.378 2.331 -4.039 V 35.983 c 0 -1.661 -0.894 -3.209 -2.331 -4.039 l -14.079 -8.128 C 46.612 23.401 45.806 23.193 45 23.193 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(0,0,0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 37.187 50.15 c -0.339 0 -0.664 -0.174 -0.85 -0.473 l -4.405 -7.096 v 6.569 c 0 0.553 -0.448 1 -1 1 s -1 -0.447 -1 -1 V 39.073 c 0 -0.447 0.296 -0.839 0.726 -0.962 c 0.431 -0.122 0.889 0.054 1.124 0.435 l 4.405 7.096 v -6.569 c 0 -0.552 0.448 -1 1 -1 s 1 0.448 1 1 V 49.15 c 0 0.446 -0.296 0.839 -0.726 0.962 C 37.37 50.137 37.278 50.15 37.187 50.15 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(0,0,0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 43.439 50.15 c -0.552 0 -1 -0.447 -1 -1 V 39.073 c 0 -0.552 0.448 -1 1 -1 h 4.467 c 0.553 0 1 0.448 1 1 s -0.447 1 -1 1 h -3.467 v 9.077 C 44.439 49.703 43.991 50.15 43.439 50.15 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(0,0,0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 46.99 45.112 h -3.552 c -0.552 0 -1 -0.448 -1 -1 s 0.448 -1 1 -1 h 3.552 c 0.553 0 1 0.448 1 1 S 47.543 45.112 46.99 45.112 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(0,0,0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 59.068 40.073 h -5.727 c -0.553 0 -1 -0.448 -1 -1 s 0.447 -1 1 -1 h 5.727 c 0.553 0 1 0.448 1 1 S 59.621 40.073 59.068 40.073 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(0,0,0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 56.204 50.15 c -0.553 0 -1 -0.447 -1 -1 V 39.073 c 0 -0.552 0.447 -1 1 -1 s 1 0.448 1 1 V 49.15 C 57.204 49.703 56.757 50.15 56.204 50.15 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(0,0,0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 45 90.01 c -1.119 0 -2.239 -0.251 -3.282 -0.752 C 18.746 78.217 6.587 54.655 5.578 19.224 c -0.091 -3.212 2.314 -5.981 5.477 -6.302 c 12.417 -1.264 21.638 -4.794 29.02 -11.11 c 2.809 -2.402 7.043 -2.403 9.851 0 c 7.381 6.316 16.602 9.846 29.02 11.11 c 3.162 0.321 5.569 3.09 5.478 6.302 c -1.011 35.431 -13.17 58.994 -36.141 70.034 C 47.239 89.759 46.119 90.01 45 90.01 z M 45 2.005 c -1.296 0 -2.592 0.442 -3.624 1.326 c -7.706 6.594 -17.276 10.274 -30.118 11.58 c -2.125 0.216 -3.742 2.086 -3.68 4.256 C 8.564 53.79 20.342 76.765 42.585 87.455 c 1.535 0.738 3.296 0.738 4.83 0 l 0 0 C 69.658 76.766 81.436 53.79 82.423 19.167 c 0.063 -2.171 -1.555 -4.04 -3.681 -4.256 C 65.901 13.605 56.33 9.925 48.624 3.331 C 47.591 2.447 46.296 2.005 45 2.005 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(0,0,0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};
