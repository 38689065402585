import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { UserIcon } from "./Icons";

// const imgDefault = (
//   <svg
//     width="55"
//     height="55"
//     viewBox="0 0 55 55"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M27.9508 0H27.0492C12.1103 0 0 12.1103 0 27.0492V27.9508C0 42.8897 12.1103 55 27.0492 55H27.9508C42.8897 55 55 42.8897 55 27.9508V27.0492C55 12.1103 42.8897 0 27.9508 0Z"
//       fill="#C8C8C8"
//     />
//   </svg>
// );

function AvatarHeader() {
  const auth = useAuth();
  const navigate = useNavigate();

  return (
    <div className="avatar">
      <UserIcon width={23} height={23} />
      <div className="info-user">{auth?.dataInfo?.data?.name}</div>
    </div>
  );
}

export default AvatarHeader;
