import axiosClient from "../../utils/axiosClient";

export enum ExpiredStatus {
  RUNNING = "RUNNING",
  EXPIRED = "EXPIRED",
}

export interface IParams {
  limit: number;
  page: number;
  expiredStatus?: ExpiredStatus;
  category?: string;
  startTime?: string; // YYYY-MM-DD
  endTime?: string; // YYYY-MM-DD
  search?: string;
}

export interface IDataCollection {
  avatar: {
    image: string;
  };
  category: string;
  expiredDate: Date;
  nftName: string;
  name: string;
  description: string;
  _id: string;
  symbol: string;
  price: number;
  isExpired: boolean;
  soldOut: boolean;
  [anyProps: string]: any;
}

export const getMyCollection = async (params: IParams) => {
  const res = await axiosClient.get("/collection/my-collections", params);
  return res;
};

export const onDeleteCollection = async (collectionId: string) => {
  const res = await axiosClient.delete(`/collection/${collectionId}`);
  return res;
};
