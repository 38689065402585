import { sampleSize } from "lodash";
import React from "react";
import { useLocation } from "react-router-dom";

const capitalizeFirstLetter = (str: string) => {
  return str[0].toUpperCase() + str.slice(1);
};

export const convertCase = (text: string) => {
  const lowercase = text.toLowerCase().replaceAll("_", " ");
  const words = lowercase.split(" ");

  const newWords = words.map((word) => capitalizeFirstLetter(word));
  return newWords.join(" ");
};

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const createRandomString = (length: number) => {
  var chars = "abcdefghijklmnopqrstufwxyzABCDEFGHIJKLMNOPQRSTUFWXYZ1234567890";
  var pwd = sampleSize(chars, length || 12);
  return pwd.join("");
};

export const dateIsValid = (dateStr: any) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (dateStr.match(regex) === null) {
    return false;
  }

  const date = new Date(dateStr);

  const timestamp = date.getTime();

  if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
    return false;
  }

  return date.toISOString().startsWith(dateStr);
};
