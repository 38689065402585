import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { get } from "lodash";
import React, { useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useStepContext } from "../../components/StepContext";
import { getSession } from "../../utils/session";
import { convertCase } from "../../utils/utils";
import { IDatasubscribecollection, subscribeCollection } from "./service";

interface Props {
  onCloseModal: () => void;
}

const elStyle = {
  base: {
    fontSize: "16px",
  },
};

const AddPaymentMethod: React.FC<Props> = (props) => {
  const { dataCollection } = useStepContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [textError, setTextError] = useState<string>();
  const [saveCard, setSaveCard] = useState<boolean>(false);

  const [nameCard, setNameCard] = useState<string>("");
  // STRIPE PAYMENT
  const stripe = useStripe();
  const elements = useElements();
  elements?.update({ locale: "en" });

  const handleSubmitPayment = async (event: any) => {
    event.preventDefault();

    if (stripe === null) {
      return;
    }
    if (elements === null) {
      return;
    }

    const element = elements.getElement(CardNumberElement);

    if (element === null) {
      return;
    }

    if (nameCard === "") {
      return setTextError("Please enter card information!");
    }

    if (nameCard.length < 6) {
      return setTextError("Card holder name must have at least 6 characters");
    }

    setLoading(true);
    const session = await getSession();

    stripe
      .createPaymentMethod({
        type: "card",
        card: element,
        billing_details: {
          name: nameCard,
        },
      })
      .then(function (result) {
        if (result.error) {
          setLoading(false);
          return setTextError(result.error.message);
        }
        setTextError("");

        const params = {
          collectionId: dataCollection?.collectionId,
          stripeBody: {
            paymentMethodId: result.paymentMethod?.id,
            customer: get(session, "data.paymentMethods[0].info.id"),
            amount: dataCollection?.amount,
          },
          shouldSavePaymentMethod: saveCard || false,
        };
        onPayment(params);
      })
      .catch(() => {
        setLoading(false);
        setTextError("Your card was declined or invalid");
      });
  };

  const onPayment = async (params: IDatasubscribecollection) => {
    setLoading(true);
    try {
      const res = await subscribeCollection(params);
      if (res && res?.status) {
        switch (res.status) {
          case "COLLECTION_NOT_FOUND":
            setLoading(false);
            setTextError("Your card was declined or invalid");
            break;
          case "SUCCESS":
            props.onCloseModal();
            setSaveCard(false);
            break;
          default:
            setLoading(false);
            setTextError(convertCase(res?.message));
            break;
        }
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setTextError("Your card was declined or invalid");
    }
  };

  return (
    <>
      {textError ? (
        <Alert key={"danger"} variant={"danger"} className="mt-4">
          {textError}
        </Alert>
      ) : (
        ""
      )}
      <Row className="payment-method mt-4">
        <Col xs={12} className="mt-3">
          <form onSubmit={handleSubmitPayment}>
            <Row>
              <Col span={6}>
                <Form.Label>Card holder name</Form.Label>
                <div>
                  <input
                    className="py-2 px-3 border-radius-stripe"
                    style={{ width: "100%" }}
                    type="text"
                    placeholder="Card holder name"
                    value={nameCard}
                    onChange={(value) => {
                      setNameCard(value.target.value);
                    }}
                  />
                </div>
              </Col>

              <Col xs={6}>
                <Form.Label>Card number</Form.Label>
                <CardNumberElement
                  options={{
                    placeholder: "Card number",
                    style: elStyle,
                    showIcon: true,
                  }}
                  className="re-border border-radius-stripe"
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xs={6}>
                <Form.Label>CVV</Form.Label>
                <CardCvcElement
                  options={{ placeholder: "CVV", style: elStyle }}
                  className="re-border border-radius-stripe"
                />
              </Col>

              <Col xs={6}>
                <Form.Label>Expiration date</Form.Label>
                <CardExpiryElement
                  options={{ placeholder: "Expiration date", style: elStyle }}
                  className="re-border border-radius-stripe"
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xs={12}>
                <Form.Check
                  type="checkbox"
                  label="Securely save this card for my later campaign"
                  onChange={(e: any) => {
                    setSaveCard(e?.target?.checked || false);
                  }}
                  style={{ color: "#6902C0" }}
                />
              </Col>
            </Row>

            <div className="btn-right mt-5 mb-2">
              <Button className="mt-5 btn-confirm-to-pay" type="submit">
                {loading ? (
                  <span>
                    <span
                      className="spinner-border spinner-border-sm mright-5"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </span>
                ) : (
                  `Pay - $${dataCollection?.amount || 0}`
                )}
              </Button>
            </div>
          </form>
        </Col>
      </Row>
    </>
  );
};

export default AddPaymentMethod;
