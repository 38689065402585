import { createContext, useContext, useState } from "react";

interface IDataContent {
  dataStep?: number;
  onChangeStep?: (data: number) => void;
  dataCollection?: any;
  onUpdateDataCollection?: (data: any) => void;
  visibleCampaign?: boolean;
  setVisibleCampaign: (visible: boolean) => void;
}

const StepContext = createContext<IDataContent | any>(null);

export const StepProvider = ({ children }: any) => {
  const [dataStep, setDataStep] = useState<number>(1);
  const [dataCollection, setDataCollection] = useState<any>();
  const [visibleCampaign, setVisibleCampaign] = useState<boolean>(false);

  const onChangeStep = (data: number) => {
    setDataStep(data);
  };

  const onUpdateDataCollection = (data: any) => {
    setDataCollection(data);
  };

  return (
    <StepContext.Provider
      value={{
        dataStep,
        onChangeStep,
        dataCollection,
        onUpdateDataCollection,
        visibleCampaign,
        setVisibleCampaign,
      }}
    >
      {children}
    </StepContext.Provider>
  );
};

export const useStepContext = () => {
  return useContext(StepContext);
};
